div.home {
  width: calc(100vw - 12px);
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

div.homepad {
  padding: 40px;
  width: 100%;
}

div.homepad > div {
}

h1 {
  font-style: normal;
  font-weight: 800;
}
