body {
  margin: 0 !important;
  padding: 0;
  overflow-x: hidden;
  font-style: normal;
  overscroll-behavior-y: none;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}

div#archive {
  background: #131316;
}
/* a {
  text-decoration: none;
} */

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #363644;
  border-radius: 2px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #535365;
  border-radius: 2px;
  cursor: default !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #84849a;
}

::selection {
  background: #665689;
}
/* 
@font-face {
  font-family: 'Bergen';
  src: url(./media/fonts/bergen/med.otf);
} */

a {
  text-decoration: none;
}

a.linktable {
  display: flex;
}

a.linktable > * {
  margin: 0 0.5rem;
}

a.linktable:link {
  color: #93d9ff;
  font-weight: 800;
}

a.linktable:visited {
  color: #bd6de0;
}

a.link > * {
  margin: 0 0.5rem;
}

a.link:link {
  color: #93d9ff;
  font-weight: 800;
}

a.link:visited {
  color: #bd6de0;
}
