@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500&family=Roboto:wght@100;300;400;500;700;900&display=swap');
div.page {
  margin-top: -4px;
  width: 100vw;
  height: calc(100vh - 45px);
}

div.page-doc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 14px);
  height: calc(100vh - 45px);
}

div.page-doc-head {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  min-height: 95px;
  margin: 10px 20px 0px 20px;
  padding: 15px;
  border-radius: 20px;
}
div.page-doc-head > div {
  margin: 4px;
}
div.page-doc-head-id {
  display: flex;
}
div.page-doc-head-id > div {
  margin-right: 8px;
}

div.page-doc-desk {
  width: calc(100vw - 30px);
  height: calc(100vh - 0px);
  margin: 10px;
  padding: 2px;
  display: flex;
}

div.page-doc-list {
  width: 220px;
  height: calc(100vh - 190px);
  margin: 10px;
  padding: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
}
div.page-doc-list img {
  width: 190px;
  height: 120px;
  object-fit: cover;
  margin: 0px 4px;
  cursor: pointer;
}

div.page-doc-view {
  width: calc(100% - 240px);
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 0px;
}

div.textarea {
  resize: none;
  border-radius: 5px;
  padding: 20px;
  background-color: transparent;
  color: white;
  outline: none;
  border: none;
  font-weight: 400;
  letter-spacing: 0.3px;
  overflow-y: scroll;
  font-family: 'Roboto Slab', sans-serif;
  white-space: pre-wrap;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}
