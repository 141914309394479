@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');
header {
  width: 100vw;
  height: 45px;
}

header a {
  color: white;
}
header div.logo {
  font-size: 20px;
  display: flex;
  padding: 4px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}

text.account {
  font-size: 40px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  text-transform: uppercase;
  text-anchor: middle;
  alignment-baseline: central;
  user-select: none;
  -webkit-user-select: none;
}

header svg {
  font-size: 20px;
  padding: 6px;
}

header svg.account {
  padding: 6px;
  cursor: pointer;
  outline: none;
}

main {
  position: absolute;
}

footer {
  margin-top: 100px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

footer > div.logos {
  width: 100vw;
  height: 100px;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
@media only screen and (max-width: 500px) {
  header {
    height: 55px;
    top: calc(100vh - 55px);
    backdrop-filter: blur(5px);
  }
}
